import React, { useState } from 'react';
import './styles.css';
import { Container } from '@mui/material';
import logo from "../src/img/logo.svg"
import { ReactComponent as Step1 } from "../src/img/step1.svg"
import { ReactComponent as Step2 } from "../src/img/step2.svg"
import { ReactComponent as Step3 } from "../src/img/step3.svg"
import { ReactComponent as Arrow } from "../src/img/arrow.svg"
import { ReactComponent as ArrowMobile } from "../src/img/arrow-mobile.svg"
import facebook from "../src/img/facebook.svg"
import instagram from "../src/img/instagram.svg"
import linkedin from "../src/img/linkedin.svg"
import {
  InlineWidget,
  PopupButton,
  useCalendlyEventListener,
} from "react-calendly";

const App = () => {
  const [details, setDetails] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const dateAndTimeSelected = () => {
    setDetails(true);
  };

  const eventScheduled = () => {
    setConfirm(true);
  };

  useCalendlyEventListener({
    onDateAndTimeSelected: () => dateAndTimeSelected(),
    onEventScheduled: (e) => eventScheduled(),
  });

  return (
    <>
      <section id="hero">
        <Container maxWidth="md" className='h-100'>
          <div className="main h-100">
            <a class="btn-logo" href="https://www.brickabode.com/" rel="noreferrer" target="_blank"><img src={logo} alt="Brick Abode logo" className="logo"/></a>
            <h1 className="title">Network Automation Services</h1>
          </div>
        </Container>
      </section>
      <section id="contact">
        <Container maxWidth="md">
          <div className="booking-wrapper">
            <h4 class="booking-title text-center">Book a meeting with our IT Business Expert<br /><b>Todd Lewis</b></h4>
            <div className="steps-wrapper">
              <Step1 className='icon-step' />
              <Arrow className="arrow-desktop icon-step" />
              <ArrowMobile className="arrow-mobile icon-step" />
              <Step2 className={`icon-step ${details ? 'icon-enabled' : 'icon-disabled'}`} />
              <Arrow className={`arrow-desktop  icon-step ${confirm ? 'icon-enabled' : 'icon-disabled'}`} />
              <ArrowMobile className={`arrow-mobile  icon-step ${confirm ? 'icon-enabled' : 'icon-disabled'}`} />
              <Step3 className={`icon-step ${confirm ? 'icon-enabled' : 'icon-disabled'}`} />
            </div>
            <div className="calendly-wrapper">
              <div>
                <InlineWidget
                  url="https://calendly.com/tlewis-ba/meeting"
                  styles={{height: 650, width: '100%'}}
                />
              </div>
            </div>
            <PopupButton
              url="https://calendly.com/tlewis-ba/meeting"
              rootElement={document.getElementById("root")}
              text="Schedule"
              className="btn-cta"
            />
          </div>
          <div className="contact-wrapper">
            <h1 className="title">Contact Us</h1>
            <div className="social-wrapper">
              <a id="btn-facebook" href="https://web.facebook.com/brickabode" rel="noreferrer" target="_blank"><img alt="Facebook icon" src={facebook} className="social-icon" /></a>
              <a id="btn-instagram" href="https://www.instagram.com/brickabode/" rel="noreferrer" target="_blank"><img alt="Instagram icon" src={instagram} className="social-icon" /></a>
              <a id="btn-linkedin" href="https://www.linkedin.com/company/brickabode/" rel="noreferrer" target="_blank"><img alt="LinkedIn icon" src={linkedin} className="social-icon" /></a>
            </div>
            <a id="btn-email" className="contact-link" href="mailto:winning@brickabode.com">winning@brickabode.com</a>
          </div>
          <div className="logo-wrapper">
            <a class="btn-logo" href="https://www.brickabode.com/" rel="noreferrer" target="_blank"><img alt="Brick Abode logo" src={logo} /></a>
          </div>
        </Container>
      </section>
    </>
  )
}

export default App;
